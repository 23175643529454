#brand               {transition: 1s ease transform;}
#overlay             {transition: 1s ease transform;}
#wood                {transition: 1s ease transform;}
#target              {transition: 1s ease transform;}
#target-feedback     {transition: 1s ease transform;}

#app.game-target {
    #brand {
        // transform:translate(calc(-50vw - .425rem + .15rem), .05rem) scale(.711864);
        // transform:translate(calc(-54vw - 0.425rem + .15rem), 0.00rem) scale(.5585);
        transform:translate(calc(-54vw - 0.425rem + .15rem), 0.00rem) scale(1);

        // position: absolute;
        // width: 4rem;
        // height: 1.15rem;

        position: absolute;
        left: 7.3rem;
        top: 20px;
        width: 2rem;
        height: .6rem;

        img {
            display: block;
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
        }
    }
    #overlay {
        transform:translateX(calc(-100vw + 2.4rem));
    }
    #wood {
        transform:scale(1.35);
    }
    #target {
        transform: scale(1.32) translate(-1.765rem, -0.41rem);
    }
    #target-feedback {
        // transform: scale(2.23) translate(-1.046rem, 1.085rem);
        // transform: scale(2.2) translate(-1.06rem, 1.1rem);
        // transform:scale(1.666) translate(-1.06rem, 1.1rem);
        transform: scale(2.2) translate(-1.06rem, .08rem);
    }
    .point-label {
        opacity: 1;
    }
    #game-players {
        transform: translateX(0);
    }
    #interface, #home {
        visibility: hidden;
    }
}