#brand {
    position: absolute;
    left: 50%;
    // width: 2.95rem;
    // width: 4rem;
    height: .8rem;
    max-width: 4rem;
    transform:translate(-50%, .4rem);
    img {
        display: block;
        // width: 100%;
        // height: 100%;
        max-height: 100%;
        max-width: 100%
    }
}