.game-score-board {
	width: 400px;
	height: 400px;
  color: #fff;
  border: 1px solid #fff;
	display: grid;
	grid-template: repeat(3, 1fr) / repeat(3, 1fr);
}

.box-score {
  border: 1px solid #fff;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 901;
}

.cross-score:after {
  position: absolute;
  content: "\d7";
  color: #fff;
  font-size: 150px;
  margin-bottom: 32px;
}

.circle-score:after {
  position: absolute;
  content: "\25CB";
  color: #fff;
  font-size: 240px;
  margin-bottom: 20px;
  margin-left: 4px;
}

.tic-tac-toe-scoreboard {
  margin: 100px auto;
}

.throw-count-table {
  float: left;
  margin-right: 100px;
  margin-top: 100px;
}
