#home {
    opacity: 1;
    visibility: visible;
    transition: opacity .5s ease, visibility .5s ease;
    #lane {
        font-size: 1.1rem;
        font-family: 'primelight';
        text-shadow: 0 .08rem .14rem $black;
        margin-top: 2.3rem;
        width: 100vw;
        text-align:center;
        margin-bottom: .75rem;
        p {
            margin-top: -.9rem;
            font-size: .2rem;
        }

        .videoWrapper {
            height: 5.05rem;
        }
    }
    #browse-games-btn {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    &.inactive {
        visibility: hidden;
        opacity: 0;
    }
}

.marketing {
    margin: 0 auto;
    margin-top: -.9rem;
    width: 70% !important;

    // For 1080p
    // width: 1316px !important;
    // height: 751px !important;
}