.button-stack {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .btn {
        padding-left: 0;
        padding-right: 0;
    }
    &-single {
        width: 3.75rem;
        .btn {
            width: 3.75rem;
            display: block;
            margin-bottom: .2rem;
        }
    }
    &-double {
        text-align:center;
        width: 75vw;
        .btn {
            width: 3.25rem;
        }
    }
    &-single {
        transform: translate(-50%, 175%);
        transition: transform .75s ease;
        &.active {
            transform: translate(-50%, -50%);
        }
    }
}
